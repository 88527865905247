<template>

  <div>

    <add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      :user-data="userData"
      @refetch-data="refetchData"
      @refetch-header="refetchHeader"
    />

    <!-- Filters -->
    <!-- <list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
    /> -->
    <b-card no-body>
      <b-card-header class="pb-50">
        <h5>
          Split Data Produksi
        </h5>
      </b-card-header>
      <b-card-body>
        <b-row>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Tanggal</label>
            <b-form-input
              v-model="userDataoee.tanggal"
              placeholder=""
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Shift</label>
            <b-form-input
              v-model="userDataoee.shift"
              placeholder=""
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Duration</label>
            <b-form-input
              v-model="userDataoee.duration"
              placeholder=""
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Last Time</label>
            <b-form-input
              v-model="userDataoee.last_time"
              placeholder=""
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Produksi</label>
            <b-form-input
              v-model="userDataoee.produksi"
              placeholder=""
              disabled
            />
          </b-col>

          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>Total Split Duration</label>
            <b-form-input
              v-model="userDataoee.duration_detail"
              placeholder=""
              disabled
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="AddData()"
              >
                <span class="text-nowrap">Add Data</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchData"
        responsive
        :fields="tableColumns"
        primary-key="id_detail"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        striped
      >

        <template #cell(tgl_from)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(tgl_to)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(desc_1)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(desc_2)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(desc_3)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <template #cell(operator)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              />
            </template>
            <b-link
              :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.fullName }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div style="line-height:80%;">
            <br>
          </div>
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id_detail}-send-icon`"
              icon="EditIcon"
              class="cursor-pointer"
              :class="`text-primary`"
              size="17"
              @click="EditData(data.item)"
            />
            <b-tooltip
              title="Edit Data"
              class="cursor-pointer"
              :target="`invoice-row-${data.item.id_detail}-send-icon`"
            />
&nbsp;
            <feather-icon
              :id="`invoice-row2-${data.item.id_detail}-send-icon`"
              icon="Trash2Icon"
              class="cursor-pointer"
              :class="`text-danger`"
              size="17"
              @click="showMsgBoxTwo(data.item.id_detail)"
            />
            <b-tooltip
              title="Delete Data"
              class="cursor-pointer"
              :target="`invoice-row2-${data.item.id_detail}-send-icon`"
            />
          </div>
          <div style="line-height:80%;">
            <br>
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BPagination, BCardHeader, BCardBody, BTooltip,
} from 'bootstrap-vue'
import router from '@/router'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onMounted, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import ListFilters from './ListFilters.vue'
// eslint-disable-next-line import/no-cycle
import useList from './useList'
import StoreModule from '../StoreModule'
import AddNew from './AddNew.vue'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListFilters,
    AddNew,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BCardHeader,
    BCardBody,
    BTooltip,

    vSelect,
  },
  directives: {
    Ripple,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const { AxiosUrl } = useAppConfig()
    const userDataoee = ref(null)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, StoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchDataEdit', { id: router.currentRoute.params.id })
      .then(response => { userDataoee.value = response.data[0] })
      .catch(error => {
        if (error.response.status === 404) {
          userDataoee.value = undefined
        }
      })

    const refetchHeader = () => {
      store.dispatch('app-user/fetchDataEdit', { id: router.currentRoute.params.id })
        .then(response => { userDataoee.value = response.data[0] })
        .catch(error => {
          if (error.response.status === 404) {
            userDataoee.value = undefined
          }
        })
    }

    const blankTask = {
      id_detail: '',
      id_data_prod: '',
      // tgl_from: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/') + new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`,
      tgl_from: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')} ${new Date().getHours()} ${new Date().getMinutes()}`,
      tgl_to: `${new Date().toJSON().slice(0, 10).replace(/-/g, '/')} ${new Date().getHours()} ${new Date().getMinutes()}`,
      duration: '0',
      level_1: '',
      level_2: '',
      level_3: '',
      keterangan: '',
      operator: localStorage.getItem('nik'),
      action: 'Add',
    }

    const isAddNewUserSidebarActive = ref(false)
    const userData = ref(JSON.parse(JSON.stringify(blankTask)))

    const roleOptions = ref([])

    onMounted(async () => {
      const result = await axios.get(`${AxiosUrl}api_mes/split-downtime-oee/listmachine`)
      const dataAPI = result.data[0]
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = dataAPI.length; i < len; i++) {
        combo.push({ label: dataAPI[i].machine, value: dataAPI[i].machine })
      }
      roleOptions.value = combo
    })

    const EditData = taskData => {
      userData.value = taskData
      isAddNewUserSidebarActive.value = true
    }

    const AddData = () => {
      userData.value = blankTask
      isAddNewUserSidebarActive.value = true
    }

    const {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList()

    return {
      userData,
      userDataoee,
      AddData,
      EditData,
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      refetchHeader,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    }
  },
  methods: {
    async showMsgBoxTwo(id) {
      console.log(id)
      this.boxTwo = id
      this.$bvModal
        .msgBoxConfirm('Are You Sure To Delete This Data?', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(async value => {
          this.boxTwo = value
          console.log(value)
          if (value === true) {
            const params = new URLSearchParams()
            params.append('param', 'delete')
            params.append('id_detail', id)
            const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/delete`, params)
            console.log(result)
            if (result.data.message === 'Data Deleted') {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Notification',
                  icon: 'BellIcon',
                  text: result.data.message,
                  variant: 'success',
                },
              })
              this.refetchData()
              this.refetchHeader()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Error',
                  icon: 'BellIcon',
                  text: 'Error Deleting Data',
                  variant: 'danger',
                },
              })
              // console.log(result)
            }
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
