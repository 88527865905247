<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ userData.action }} Split Data Produksi
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- From -->
          <validation-provider
            #default="validationContext"
            name="From"
            rules="required"
          >
            <b-form-group
              label="From"
              label-for="from"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="userData.tgl_from"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                @input="calcDuration()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- To -->
          <validation-provider
            #default="validationContext"
            name="To"
            rules="required"
          >
            <b-form-group
              label="To"
              label-for="to"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="userData.tgl_to"
                class="form-control"
                :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                @input="calcDuration()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Duration -->
          <validation-provider
            #default="validationContext"
            name="Duration"
            rules="required|integer"
          >
            <b-form-group
              label="Duration (menit)"
              label-for="duration"
            >
              <b-form-input
                id="item-code-qad"
                v-model="userData.duration"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder=""
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level 1 -->
          <validation-provider
            #default="validationContext"
            name="Level 1"
            rules="required"
          >
            <b-form-group
              label="Level 1"
              label-for="level1"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.level_1"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lv1Options"
                :reduce="val => val.value"
                :clearable="false"
                input-id="level1"
                @input="getLv2()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level 2 -->
          <validation-provider
            #default="validationContext"
            name="Level 2"
            rules="required"
          >
            <b-form-group
              label="Level 2"
              label-for="level2"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.level_2"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="lv2Options"
                :reduce="val => val.value"
                :clearable="false"
                input-id="level2"
                @input="getLv3()"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Level 3 -->

          <b-form-group
            label="Level 3"
            label-for="level3"
          >
            <v-select
              v-model="userData.level_3"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="lv3Options"
              :reduce="val => val.value"
              :clearable="false"
              input-id="level3"
            />
          </b-form-group>

          <!-- Keterangan -->
          <!-- <validation-provider
            #default="validationContext"
            name="Keterangan"
            rules="required"
          > -->
          <b-form-group
            label="Keterangan"
            label-for="keterangan"
          >
            <b-form-input
              id="keterangan"
              v-model="userData.keterangan"
            />

            <!-- <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback> -->
          </b-form-group>
          <!-- </validation-provider> -->

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="$emit('update:is-add-new-user-sidebar-active', false),$emit('refetch-data')"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { toRefs, ref, onMounted } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import { useToast } from 'vue-toastification/composition'
import useAppConfig from '@core/app-config/useAppConfig'
import router from '@/router'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      lv2Options: [],
      lv3Options: [],
      tgl_now: new Date(),
    }
  },
  async created() {
    let last$inputValue = this.userData.level_1
    setInterval(() => {
      const newValue = this.userData.level_1
      const newValue2 = this.userData.level_2
      const newValue3 = this.userData.level_3
      if (last$inputValue !== newValue) {
        this.getLv2()
        this.userData.level_2 = newValue2
        this.getLv3()
        this.userData.level_3 = newValue3
        last$inputValue = newValue
      }
    }, 0.1)
  },
  methods: {
    resetuserData({ emit }) {
      emit('refetch-data')
      emit('update:is-add-new-user-sidebar-active', false)
    },
    async getLv2() {
      const lv1 = JSON.stringify(this.userData.level_1)
      this.userData.level_2 = ''
      this.userData.id_data_prod = router.currentRoute.params.id

      const params = new URLSearchParams()
      params.append('id_deploy', lv1)
      params.append('level', 2)
      params.append('id_group_oee', lv1)
      const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/deploydata`, { id_deploy: lv1, level: 2, id_group_oee: 23 }) // id_group_oee 23 = Fitting Karawang
      const DataAPI = result.data[0]
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: DataAPI[i].description, value: DataAPI[i].id_deploy })
      }
      this.lv2Options = combo
    },
    async getLv3() {
      const lv2 = JSON.stringify(this.userData.level_2)
      this.userData.level_3 = ''

      const params = new URLSearchParams()
      params.append('id_deploy', lv2)
      params.append('level', 3)
      params.append('id_group_oee', lv2)
      const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/deploydata`, { id_deploy: lv2, level: 3, id_group_oee: 23 }) // id_group_oee 23 = Fitting Karawang
      const DataAPI = result.data[0]
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: DataAPI[i].description, value: DataAPI[i].id_deploy })
      }
      this.lv3Options = combo
    },
    calcDuration() {
      // if (this.userData.tgl_to instanceof Date === false) {
      //   this.userData.tgl_to = this.tgl_now
      //   console.log('cssd222')
      // }
      // if (this.userData.tgl_from instanceof Date === false) {
      //   this.userData.tgl_from = new Date()
      // }
      const tglfrom = new Date(this.userData.tgl_from)
      const tglto = new Date(this.userData.tgl_to)
      if (!isNaN(Date.parse(tglfrom)) === false) {
        this.userData.tgl_from = new Date()
      }
      if (!isNaN(Date.parse(tglto)) === false) {
        this.userData.tgl_to = new Date()
      }
      const diff = Math.abs(new Date(this.userData.tgl_to) - new Date(this.userData.tgl_from))
      const minutes = Math.floor((diff / 1000) / 60)
      this.userData.duration = minutes
    },
  },
  setup(props, { emit }) {
    const toast = useToast()

    const lv1Options = ref([])
    onMounted(async () => {
      const result = await axios.post(`${useAppConfig().AxiosUrl}api_mes/split-downtime-oee/deploydatalv1`)
      const DataAPI = result.data[0]
      const combo = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0, len = DataAPI.length; i < len; i++) {
        combo.push({ label: DataAPI[i].description, value: DataAPI[i].id_deploy })
      }
      lv1Options.value = combo
    })

    // console.log(lv1Options)

    const { userData } = toRefs(props)
    const resetuserData = () => {
      this.refetchData()
    }

    const onSubmit = () => {
      store.dispatch('app-user/crudData', userData.value)
        .then(response => {
          emit('refetch-data')
          emit('refetch-header')
          emit('update:is-add-new-user-sidebar-active', false)
          if (response.data.message === 'Data Saved' || response.data.message === 'Data Updated') {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'success',
              },
            })
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'BellIcon',
                text: response.data.message,
                variant: 'danger',
              },
            })
            // console.log()
          }
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      lv1Options,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
